import axios from 'axios';
import { SESSION_STORAGE_KEYS } from 'src/utils/constants';

const constructBaseUrl = domain => {
  const isEURegion = process.env.IS_EU_REGION === 'true';
  let baseUrl = '';
  const stage = process.env.STG;
  if (domain === 'devx') {
    const stageName = new URL(window.location.origin).hostname?.split('.')[0];
    baseUrl =
      stageName === 'live'
        ? `//tenants.fabric.inc/v1`
        : `//${stageName}.tenants.fabric.inc/v1`;
  } else if (isEURegion) {
    baseUrl = !stage
      ? 'https://live.eu.identity.fabric.inc/ums/v2'
      : 'https://eu.identity-sbx.fabric.inc/ums/v2';
  } else {
    baseUrl = !stage
      ? 'https://api.identity.fabric.zone/ums/v2'
      : `https://api.identity-${process.env.STG}.fabric.zone/ums/v2`;
  }
  return baseUrl;
};

const axiosInstance = () =>
  axios.create({
    responseType: 'json',
    headers: {
      Accept: 'application/json',
    },
  });

const interceptorsRequest = (domainInstance, domain) => {
  domainInstance.interceptors.request.use(function (request) {
    if (domain === 'identityV2') {
      request.baseURL =
        process.env.NEW_USERS_SELF_API_URL + '/fiamDefault/ums/v2';
    } else {
      request.baseURL = constructBaseUrl(domain);
    }
    request.headers.Authorization = `Bearer ${sessionStorage.getItem(
      SESSION_STORAGE_KEYS.ACCESS_TOKEN
    )}`;
    if (domain === 'devx') {
      request.headers['x-fabric-tenant-id'] = sessionStorage.getItem(
        SESSION_STORAGE_KEYS.ACCOUNT_ID
      );
    }
    return request;
  });
};

const domainsAxiosInstances = {
  identity: axiosInstance(),
  devx: axiosInstance(),
  identityV2: axiosInstance(),
};
const { identity, devx, identityV2 } = domainsAxiosInstances;

interceptorsRequest(identity, 'identity');
interceptorsRequest(devx, 'devx');
interceptorsRequest(identityV2, 'identityV2');

export default domainsAxiosInstances;
